html body{
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: 0;
  scroll-behavior: smooth;
  max-width: 100%;
  overflow-x: hidden;
  }

#signup{
   background-image: url('mapBackdrop.png');
   background-repeat: no-repeat;
   background-attachment: fixed;
   background-position: center; 
   background-size:cover;
   height: 100vh;
  }
  
  #learnMore{
    background-color: #0b6623;
    height: 100vh;
  }
  #ourMission{
    background-color: white;
    height: 100vh;
  }

#logo{
  width: 30vw;
}

  